
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import StackedBarChartFilterable from '../../filterables/StackedBarChartFilterable.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '../FilterWidget.vue';
import {
	PermissionsGroup,
	ProjectCashFlow,
	ValueText,
} from '@/store/models.def';
import { DataViewFormatter } from '../../elements/charts/helpers/dataViewFormatter';
import SelectFilter from '@/components/reports-v2/components/filters/SelectFilter.vue';
import moment from 'moment';
import gdbx from '@/store/modules/gdbx';
import { GobiColor } from '@/helpers/color';

@Component({
	components: {
		StackedBarChartFilterable,
		SelectFilter,
	},
})
export default class ProjectSummaryBarChart extends FilterWidget {
	@Prop({ default: () => '' }) public readonly selectedProjectCode!: string;

	public get permissionIds(): PermissionsGroup[] {
		return ['transactions'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = [];

	public ytdCost = 0;
	public ytdCollection = 0;
	public ytdBilling = 0;
	public currentMonthCost = 0;
	public currentMonthBilling = 0;
	public currentMonthCollection = 0;
	public totalCollection = 0;
	public totalCost = 0;
	public totalBilling = 0;

	public selected: string = 'total' || null;
	public series: echarts.ECharts[] | any = [];

	public xAxisData = ['Cost', 'Billing', 'Collection'].reverse();

	public get dataViewFormatter() {
		return DataViewFormatter.horizontalBarChart;
	}

	public get currency() {
		return gdbx.currencySymbol;
	}

	public get selectionList(): ValueText[] {
		const list = [
			{ text: 'Current Month', value: 'currentMonth' },
			{ text: 'This Year', value: 'thisYear' },
			{ text: 'Total', value: 'total' },
		];

		return list;
	}

	public get expensiveHook() {
		const { selectedProjectCode, selectedAsOfDate, selected } = this;
		return JSON.stringify([selectedProjectCode, selectedAsOfDate, selected]);
	}

	public async expensiveCalc() {
		const projectCashFlowRef = FilteredDatabase.ref(
			'projectCashFlow',
		).includes('projectCode', [this.selectedProjectCode]);

		const projectCashFlowCards: ProjectCashFlow[] = await (
			await projectCashFlowRef.get()
		).getCards();

		this.ytdCost = 0;
		this.ytdCollection = 0;
		this.ytdBilling = 0;
		this.currentMonthCost = 0;
		this.currentMonthBilling = 0;
		this.currentMonthCollection = 0;
		this.totalCollection = 0;
		this.totalCost = 0;
		this.totalBilling = 0;

		let seriesData: number[] = [];

		projectCashFlowCards.forEach((item) => {
			const ytdYear = moment(this.selectedAsOfDate).year();
			const ytdFirstMonth = moment(this.selectedAsOfDate)
				.startOf('year')
				.month();
			const currentMonth =
				moment(this.selectedAsOfDate).startOf('month').month() + 1;

			if (
				item.year === ytdYear &&
				item.month >= ytdFirstMonth &&
				item.month <= currentMonth
			) {
				this.ytdCost +=
					item.stockIssue +
					item.stockReturn +
					item.otherStock +
					item.expenses +
					item.subCon +
					item.labour;

				this.ytdCollection +=
					item.customerPayment + item.customerContra + item.customerRefund;

				this.ytdBilling +=
					item.customerInvoice + item.customerDebit + item.customerCredit;
			}

			if (item.year === ytdYear && item.month === currentMonth) {
				this.currentMonthCost +=
					item.stockIssue +
					item.stockReturn +
					item.otherStock +
					item.expenses +
					item.subCon +
					item.labour;

				this.currentMonthCollection +=
					item.customerPayment + item.customerContra + item.customerRefund;

				this.currentMonthBilling +=
					item.customerInvoice + item.customerDebit + item.customerCredit;
			}

			if (item.year <= ytdYear || item.month <= currentMonth) {
				this.totalCollection +=
					item.customerPayment + item.customerContra + item.customerRefund;
				this.totalCost +=
					item.stockIssue +
					item.stockReturn +
					item.otherStock +
					item.expenses +
					item.subCon +
					item.labour;
				this.totalBilling +=
					item.customerInvoice + item.customerDebit + item.customerCredit;
			}
		});

		switch (this.selected) {
			case 'total':
				seriesData.push(this.totalCollection);
				seriesData.push(this.totalBilling);
				seriesData.push(this.totalCost);
				break;
			case 'thisYear':
				seriesData.push(this.ytdCollection);
				seriesData.push(this.ytdBilling);
				seriesData.push(this.ytdCost);
				break;
			case 'currentMonth':
				seriesData.push(this.currentMonthCollection);
				seriesData.push(this.currentMonthBilling);
				seriesData.push(this.currentMonthCost);
				break;
			default:
				seriesData = [];
		}

		const selectedName = this.selected
			? this.selectionList.filter((vt) => vt.value === this.selected)[0].text
			: '';

		this.series = [
			{
				name: selectedName,
				data: seriesData.map((item, index) => ({
					value: item,
					itemStyle: {
						color:
							index === 0
								? GobiColor.TEAL
								: index === 1
								? GobiColor.YELLOW
								: GobiColor.PINK,
					},
				})),
				type: 'bar',
			},
		];

		this.saveHistory('series', 'xAxisData');
	}

	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
