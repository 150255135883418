
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ListFilter from './ListFilter.vue';
import ListFilterContainer from './ListFilterContainer.vue';
import gdbx from '@/store/modules/gdbx';
import { ValueText } from '@/store/models.def';

@Component({
	components: {
		ListFilter,
	},
})
export default class GeneralListFilter extends ListFilterContainer {
	@Prop({ default: '' }) public readonly filterId!: string;
	@Prop({ default: 'fa fa-google' }) public readonly iconClass!: string;
	@Prop({ default: '' }) public readonly filterName!: string;
	@Prop({ default: () => []}) public readonly optionsList!: ValueText[];
	@Prop({ default: () => []}) public readonly categoryList!: ValueText[];

	public get options() {
		return this.optionsList;
	}
	public get categories() {
		return this.categoryList;
	}
}
