
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import GeneralFilterable from '@/components/reports-v2/components/filterables/GeneralFilterable.vue';
import userx from '@/store/modules/userx';
import router from '@/router';
import LastSync from '@/components/LastSync.vue';
import ProjectRadioFilter from '@/components/reports-v2/components/filters/ProjectRadioFilter.vue';
import SelectFilter from '@/components/reports-v2/components/filters/SelectFilter.vue';
import DateFilter from '@/components/reports-v2/components/filters/DateFilter.vue';
import GeneralListFilter from '@/components/reports-v2/components/filters/GeneralListFilter.vue';
import StockGroupListFilter from '@/components/reports-v2/components/filters/StockGroupListFilter.vue';
import ProjectGeneralTable from '@/components/reports-v2/components/codedWidgets/project/ProjectGeneralTable.vue';
import ProjectSummaryBarChart from '@/components/reports-v2/components/codedWidgets/project/ProjectSummaryBarChart.vue';
import ProjectTotalBillingPieChart from '@/components/reports-v2/components/codedWidgets/project/ProjectTotalBillingPieChart.vue';
import moment from 'moment';
import Message from 'primevue/message';
import gdbx from '@/store/modules/gdbx';

@Component({
	components: {
		LastSync,
		GeneralFilterable,
		ProjectRadioFilter,
		SelectFilter,
		GeneralListFilter,
		StockGroupListFilter,
		ProjectGeneralTable,
		ProjectSummaryBarChart,
		ProjectTotalBillingPieChart,
		DateFilter,
		Message,
	},
})
export default class ProjectTracker extends Vue {
	public defaultProjectCode = '';
	public selectedDay: number = moment().endOf('month').valueOf();

	public mounted() {
		this.track();
		gdbx.allProjects.length
        ? this.defaultProjectCode = gdbx.allProjects[0].value : this.defaultProjectCode = '';
	}

	public track() {
		this.$ga.set('userId', userx.user!.uid + '_' + userx.user!.email);
		this.$ga.page(router);
	}

	public get selectedAsOfDate() {
		return moment().endOf('month').valueOf();
	}

	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
