
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import BaseFilter from './BaseFilter.vue';
import { ValueText } from '@/store/models.def';

interface CategoryData {
	value: string[];
	text: string;
	selected: boolean;
}

@Component({
	components: {},
})
export default class RadioFilter extends BaseFilter {
	@Prop({ default: () => [] }) public options!: ValueText[];
	@Prop({ default: () => [] }) public categories!: ValueText[];
	@Prop({ default: '' }) public value!: string;
	@Prop({ default: true }) public searchable!: boolean;

	public selected: string = this.value;
	public searchString: string = '';
	public categoryDatas: CategoryData[] = [];
	public optionsLimit = 10;

	@Watch('value')
	public onValueChange(newVal: string, oldVal: string) {
		this.selected = newVal;
	}
	@Watch('selected')
	public onSelectedChange(newVal: string, oldVal: string) {
		if (this.selected !== this.value) {
			this.$emit('input', this.selected);
		}
	}

	public get limitedOptions() {
		return this.filteredOptions.slice(0, this.optionsLimit);
	}

	public get dropDownText() {
		const itemName = this.filteredOptions.filter(
			(item) => item.value === this.selected,
		);
		return itemName.length > 0 ? itemName[0].text : 'Please select';
	}

	public get filteredOptions(): ValueText[] {
		if (!this.searchString || !this.searchable) {
			return this.options;
		}
		const keywords = this.searchString
			.toLowerCase()
			.split(/,\s*/g)
			.map((s) => s.trim())
			.filter((s) => s);

		return this.options.filter(
			(data) =>
				this.match(keywords, data.text) || this.match(keywords, data.value),
		);
	}

	public onLoadMoreOptions() {
		this.optionsLimit += 10;
	}

	public match(keywords: string[], target: string) {
		const text = target.toLowerCase();
		for (const keyword of keywords) {
			if (text.indexOf(keyword) !== -1) {
				return true;
			}
		}
		return false;
	}

	public onClick() {
		this.isEditing = false;
		(this.$refs.dropdown as any).hide(true);
	}
}
