
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import PieChartFilterable from '@/components/reports-v2/components/filterables/PieChartFilterable.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import { PermissionsGroup, ProjectCashFlow } from '@/store/models.def';
import { GobiColor } from '@/helpers/color';
import { DataViewFormatter } from '@/components/reports-v2/components/elements/charts/helpers/dataViewFormatter';
import moment from 'moment';
import { TooltipFormatter } from '../../elements/charts/helpers/tooltipFormatter';
import gdbx from '@/store/modules/gdbx';
import { roundToFixed } from '@/util/number';

@Component({
	components: {
		PieChartFilterable,
	},
})
export default class ProjectTotalBillingPieChart extends FilterWidget {
	@Prop({ default: () => '' }) public readonly selectedProjectCode!: string;

	public get permissionIds(): PermissionsGroup[] {
		return ['transactions'];
	}

	public filterIds: Array<
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = [];

	public pieDatas: Array<{
		name: string;
		value: number;
		itemStyle: { color: any };
	}> = [];

	public get currentYearRange(): [number, number] {
		return [
			moment(this.selectedAsOfDate).startOf('year').valueOf(),
			moment(this.selectedAsOfDate).startOf('month').valueOf(),
		];
	}

	public get dataViewFormatter() {
		return DataViewFormatter.pieChart;
	}

	public get tooltipFormatter() {
		return TooltipFormatter.pieChart;
	}

	public get currency() {
		return gdbx.currencySymbol;
	}

	public get decimal() {
		return gdbx.numDecimal;
	}

	public get expensiveHook() {
		const { selectedProjectCode, selectedAsOfDate } = this;
		return JSON.stringify([selectedProjectCode, selectedAsOfDate]);
	}

	public async expensiveCalc() {
		const projectCashFlowRef = FilteredDatabase.ref(
			'projectCashFlow',
		).includes('projectCode', [this.selectedProjectCode]);

		const projectCashFlowCards: ProjectCashFlow[] = await (
			await projectCashFlowRef.get()
		).getCards();

		let collection = 0;
		let billing = 0;

		projectCashFlowCards.forEach((item) => {
			const ytdYear = moment(this.selectedAsOfDate).year();
			const currentMonth =
				moment(this.selectedAsOfDate).startOf('month').month() + 1;

			if (item.year <= ytdYear || item.month <= currentMonth) {
				collection +=
					item.customerPayment + item.customerContra + item.customerRefund;

				billing +=
					item.customerInvoice + item.customerDebit + item.customerCredit;
			}
		});

		this.pieDatas = [
			{
				name: 'Collection',
				value: roundToFixed(collection, this.decimal),
				itemStyle: { color: GobiColor.TEAL },
			},
			{
				name: 'Pending',
				value: roundToFixed(billing - collection, this.decimal),
				itemStyle: { color: GobiColor.ORANGE },
			},
		];
		this.saveHistory('pieDatas');
	}
}
