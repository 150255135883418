
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ListFilter from './ListFilter.vue';
import ListFilterContainer from './ListFilterContainer.vue';
import gdbx from '@/store/modules/gdbx';

@Component({
	components: {
		ListFilter,
	},
})
export default class StockGroupListFilter extends ListFilterContainer {
	public get options() {
		const list = { ...gdbx.stockGroupNames };
		const result: Array<{ text: string; value: string }> = [];
		for (const [key, value] of Object.entries(list)) {
			const object = { text: value, value: key };
			result.push(object);
		}
		return result;
	}
}
