
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import BaseFilterable from '@/components/reports-v2/components/filterables/BaseFilterable.vue';
import {
	PermissionsGroup,
	ProjectCashFlow,
	ProjectDbCard,
} from '@/store/models.def';
import gdbx from '@/store/modules/gdbx';
import moment from 'moment';
import { addComma } from '@/util/number';
import DataInfo from '@/components/reports-v2/components/elements/DataInfo.vue';

@Component({
	components: {
		BaseFilterable,
		DataInfo,
	},
})
export default class ProjectGeneralTable extends FilterWidget {
	@Prop({ default: () => '' }) public readonly selectedProjectCode!: string;

	public get permissionIds(): PermissionsGroup[] {
		return ['transactions'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = [];

	public projectPeriod: number = 0;
	public pastDays: number = 0;
	public projectValue: number = 0;
	public formattedProjectValue: string = '';
	public budgetCost: string = '';
	public projectStatus: string = '';

	public ytdCost = 0;
	public ytdCollection = 0;
	public ytdBilling = 0;
	public currentMonthCost = 0;
	public currentMonthBilling = 0;
	public currentMonthCollection = 0;
	public totalCollection = 0;
	public totalCost = 0;
	public totalBilling = 0;
	public formattedTotalBilling = '';

	@Watch('projectInfo', { immediate: true })
	public onProjectDetailsChange(newVal) {
		this.$emit('returnProjectDetails', newVal);
	}

	public formatNumber(value: number) {
		return this.currencySymbol + ' ' + addComma(value, this.numDecimal, true);
	}

	public get projectInfo() {
		return {
			projectPeriod: this.projectPeriod,
			pastDays: this.pastDays,
			projectStatus: this.projectStatus,
			formattedProjectValue: this.formattedProjectValue,
			budgetCost: this.budgetCost,
		};
	}

	public get currencySymbol() {
		return gdbx.currencySymbol;
	}

	public get numDecimal() {
		return gdbx.numDecimal;
	}

	public get dateFormatted() {
		return [];
	}

	public get expensiveHook() {
		const { selectedProjectCode, selectedAsOfDate } = this;
		return JSON.stringify([selectedProjectCode, selectedAsOfDate]);
	}

	public async expensiveCalc() {
		const globalProjectRef = FilteredDatabase.ref('globalProjects').includes(
			'code',
			[this.selectedProjectCode],
		);

		const globalProjectCards = (await (
			await globalProjectRef.get()
		).getCards()) as ProjectDbCard[];

		this.projectPeriod = 0;
		this.pastDays = 0;
		this.formattedProjectValue = '';
		this.budgetCost = '';
		this.projectStatus = '';

		if (globalProjectCards.length > 0) {
			this.projectStatus = globalProjectCards[0].status;
			this.projectValue = globalProjectCards[0].projectValue;
			this.formattedProjectValue =
				this.currencySymbol +
				' ' +
				addComma(globalProjectCards[0].projectValue, this.numDecimal, true);
			this.budgetCost =
				this.currencySymbol +
				' ' +
				addComma(globalProjectCards[0].projectCost, this.numDecimal, true);
			if (
				(this.selectedAsOfDate >= globalProjectCards[0].startDate &&
					this.selectedAsOfDate <= globalProjectCards[0].endDate) ||
				this.selectedAsOfDate > globalProjectCards[0].endDate
			) {
				this.projectPeriod = moment(globalProjectCards[0].endDate).diff(
					moment(globalProjectCards[0].startDate),
					'day',
				);
				this.pastDays = Math.abs(
					moment(this.selectedAsOfDate).diff(
						moment(globalProjectCards[0].startDate),
						'days',
					),
				);
			}
		}

		const projectCashFlowRef = FilteredDatabase.ref('projectCashFlow').includes(
			'projectCode',
			[this.selectedProjectCode],
		);

		const projectCashFlowCards: ProjectCashFlow[] = await (
			await projectCashFlowRef.get()
		).getCards();

		this.ytdCost = 0;
		this.ytdCollection = 0;
		this.ytdBilling = 0;
		this.currentMonthCost = 0;
		this.currentMonthBilling = 0;
		this.currentMonthCollection = 0;
		this.totalCollection = 0;
		this.totalCost = 0;
		this.totalBilling = 0;

		const ytdYear = moment(this.selectedAsOfDate).year();

		const ytdFirstMonth = moment(this.selectedAsOfDate).startOf('year').month();

		const currentMonth =
			moment(this.selectedAsOfDate).startOf('month').month() + 1;

		projectCashFlowCards.forEach((item) => {
			if (
				item.year === ytdYear &&
				item.month >= ytdFirstMonth &&
				item.month <= currentMonth
			) {
				this.ytdCost +=
					item.stockIssue +
					item.stockReturn +
					item.otherStock +
					item.expenses +
					item.subCon +
					item.labour;

				this.ytdCollection +=
					item.customerPayment + item.customerContra + item.customerRefund;

				this.ytdBilling +=
					item.customerInvoice + item.customerDebit + item.customerCredit;
			}

			if (item.year === ytdYear && item.month === currentMonth) {
				this.currentMonthCost +=
					item.stockIssue +
					item.stockReturn +
					item.otherStock +
					item.expenses +
					item.subCon +
					item.labour;

				this.currentMonthCollection +=
					item.customerPayment + item.customerContra + item.customerRefund;

				this.currentMonthBilling +=
					item.customerInvoice + item.customerDebit + item.customerCredit;
			}

			if (item.year <= ytdYear) {
				if (item.year === ytdYear && item.month > currentMonth) {
					this.totalCollection += 0;
					this.totalCost += 0;
					this.totalBilling += 0;
				} else {
					this.totalCollection +=
						item.customerPayment + item.customerContra + item.customerRefund;
					this.totalCost +=
						item.stockIssue +
						item.stockReturn +
						item.otherStock +
						item.expenses +
						item.subCon +
						item.labour;
					this.totalBilling +=
						item.customerInvoice + item.customerDebit + item.customerCredit;
				}
			}

			this.formattedTotalBilling =
				this.currencySymbol +
				' ' +
				addComma(this.totalBilling, this.numDecimal, true);
		});

		// this.saveHistory('tableItems', 'tableFields');
	}

	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
