
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import RadioFilter from './RadioFilter.vue';
import gdbx from '@/store/modules/gdbx';
import RadioFilterContainer from './RadioFilterContainer.vue';

@Component({
	components: {
		RadioFilter,
	},
})
export default class ProjectRadioFilter extends RadioFilterContainer {
	public get options() {
		return gdbx.allProjects;
	}
}
